<template>
  <div>
    <div class="assessBox AssesstutorshipInfo">
      <div class="app-container">
        <section class="titlebox">
          <el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
          <div class="title">绩效辅导</div>
        </section>
        <section class="picbox">
          <div>
            <el-avatar
              :size="100"
              fit="contain"
              :src="staffInfo.avatarUrl"
            >
            <!--staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.avatarUrl  {{staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.name}}  {{staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.orgName}}  {{staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.jobName}}!-->
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-avatar>
          </div>
          <div>
            <h2>{{staffInfo.staffName}}</h2>
            <p>部门：{{staffInfo.orgName}} &nbsp; 职位：{{staffInfo.officeName}}</p>
          </div>
        </section>
        <section class="viewbox">
          <el-form :model="searchForm" inline>
            <el-form-item label="绩效考核名称">
              <el-select v-model="searchForm.performanceId" size="small" placeholder="请选择绩效考核名称" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="辅导时间">
              <el-date-picker
                v-model="searchForm.startTime"
                type="datetime"
                size="small"
                value-format="yyyy-MM-dd HH:mm:ss"
                default-time="00:00:00"
              ></el-date-picker>
              <span style="margin:0 5px">-</span>
                 <el-date-picker
                v-model="searchForm.endTime"
                type="datetime"
                size="small"
                value-format="yyyy-MM-dd HH:mm:ss"
                default-time="'23:59:59"
              ></el-date-picker>
            </el-form-item>
            <el-form-item style="margin-left:5px">
              <el-button type="primary" @click="getRecord" icon="el-icon-search" size="small">查询</el-button>
            </el-form-item>
          </el-form>
           <el-table
              :data="tableData"
              stripe
              v-loading="loading"
              header-row-class-name="tableClass">
              <el-table-column label="绩效辅导时间" align="center" prop="tutorshipTime"></el-table-column>
              <el-table-column label="绩效考核模板" align="center" prop="performanceName"></el-table-column>
              <el-table-column label="辅导内容" min-width="300" align="center" prop="tutorshipContent"></el-table-column>
           </el-table>
           <el-pagination
             @size-change="sizeChange"
             @current-change="currentChange"
             :current-page="table.pageNo"
             :page-sizes="[20, 40,50, 80, 100]"
             :page-size="table.pageSize"
             layout="total, sizes, prev, pager, next, jumper"
             :total="table.totalNum">
           </el-pagination>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import {performanceNameChoice, tutorshipRecord} from '@/api/performance';
export default {
  data() {
    return {
      searchForm: {
        performanceId: '',
        timeArr: []
      },
      staffInfo: JSON.parse(this.$route.params.obj),
      loading: false,
      options: [],
      tableData: [],
      table: {
        pageNo: 1,
        pageSize: 20,
        totalNum: 0
      }
    };
  },
  mounted() {
     performanceNameChoice({}).then((res) => {
      if (res) {
        this.options = res.list;
      }
    });
    this.getRecord();
  },
  methods: {
    sizeChange(val) {
      this.table.pageSize = val;
    },
    currentChange(val) {
      this.table.pageNo = val;
    },
    getRecord() {
      const params = {
        performanceId: this.searchForm.performanceId,
        staffId: this.$route.params.staffId,
        startTime: this.searchForm.startTime,
        endTime: this.searchForm.endTime
      };

      this.loading = true;
      tutorshipRecord(params).then((res) => {
        if (res.list) {
          this.tableData = res.list;
          this.table.totalNum = res.totalNum;
          this.loading = false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.picbox {
  padding: 0px 0 0 100px;
  background: #fff;
  margin: 0 20px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: 140px;
  :first-child {
    margin-right: 10px;
  }
  h2 {
    margin: 10px 0 0 0;
  }
  p {
    margin: 15px 0px;
    color: #999999;
  }
}
.assessBox {
  .app-container {
    color: #484848;
    padding: 0;
    background-color: #f6f7fb;
    .titlebox {
      text-align: center;
      line-height: 17px;
      background: #fff;
      font: bold 18px/25px "Microsoft YaHei";
      padding: 14px 40px;
      > .el-button {
        float: left;
        color: #484848;
        transform: translateY(-8px);
        font-size: 16px;
      }
      text-align: center;
      line-height: 17px;
      font: bold 18px/25px "Microsoft YaHei";
      margin: 20px;
    }
    .viewbox {
      font-size: 14px;
      margin: 0 20px;
      padding: 30px 5%;
      overflow: hidden;
      background-color: #fff;
    }
  }
}
  .tableClass th {
    background-color: #f0f8ff;
    color: #484848;
  }
  .el-pagination {
    float: right;
    margin-top: 20px;
  }
</style>
